.gpt3__footer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    background: var(--color-footer);
}

.gpt3__footer-heading {
   width: 100%;
   text-align: center;
   margin-bottom: 3rem;
}

.gpt3__footer-heading h1 {
   font-family: var(--font-family);
   font-size: 40px;
   font-weight: 800;
   line-height: 75px;
}

.gpt3__footer-btn button {
   display: flex;
   justify-content: flex-end;
   align-items: center; 
   background-color: transparent;
   color: #fff;
   border: 1px solid #fff;
   font-family: var(--font-family);
   font-weight: 500;
   font-size: 16px;
   cursor: pointer;
   padding: 1.5rem 2rem;
}

.gpt3__footer-copyright {
    margin-top: 2rem;
    text-align: center;
    width: 100%;
}

.gpt3__footer-copyright p {
    font-size: 12px;
    font-family: var(--font-family);
    line-height: 15px;
    color:#fff;
}

@media screen and (max-width: 850px) {
    .gpt3__footer-heading h1 {
        font-size: 44px;
        line-height: 50px;
    }
}

@media screen and (max-width: 550px) {
    .gpt3__footer-heading h1 {
        font-size: 34px;
        line-height: 42px;
    }

    .gpt3__footer-btn button{
        font-size: 14px;
        line-height: 20px;
    }
}

@media screen and (max-width: 400px) {
    .gpt3__footer-heading h1 {
        font-size: 27px;
        line-height: 38px;
    }
}