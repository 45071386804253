.gpt3__header {
    display: flex;
}

.gpt3__header-content {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    margin-right: 5rem;
}

.gpt3__header-content h1 {
    font-family: var(--font-family);
    font-weight: 800;

}

.gpt3__header-content p {
    font-family: var(--font-family);
    color: var(--color-text);
    margin-top: 1rem
}

.gpt3__header-content__input {
    width: 100%;
    margin: 2rem 0 1rem;
    display: flex;
}

.gpt3__header-content__input input {
    background: var(--color-footer);
    color: #fff;
    font-family: var(--font-family);
    width: 100%;
    border: 2px solid var(--color-foter);
    border-radius: 5px 0px 0px 5px;

}

.gpt3__header-content__input button {
    flex: 0.6;
    width: 100%;
    min-height: 50px;
    font-family: var(--font-family);
    font-weight: bold;
    cursor: pointer;
    outline: none;
    border: none;
    border-radius: 5px ;
    padding: 0 1rem;

}

.gpt3__header-image img {
    width: 100%;
    height: 100%;

}

@media screen and (max-width: 1050px) {
    .gpt3__header {
        flex-direction: column;
    }

    .gpt3__header-content {
        margin: 0 0 3rem;
    }
}

@media screen and (max-width: 650px) {
    .gpt3__header h1 {
        font-size: 48px;
        line-height: 60px;
    }

    .gpt3__header p {
        font-size: 16px;
        line-height: 24px;
    }
}

@media screen and (max-width: 490px) {
    .gpt3__header h1 {
        font-size: 36px;
        line-height: 48px;
    }

    .gpt3__header p {
        font-size: 14px;
        line-height: 24px;
    }

    .gpt3__header-content__input input,
    .gpt3__header-content__input button {
        font-size: 12px;
        line-height: 16px;
    }
}

/* skipped mobile screen setting here */